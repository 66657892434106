import React from 'react';
import PropTypes from 'prop-types';

import { SVG } from '../../components/icons';
import { Markdown } from '../../components/utils';

import styles from './feature.module.scss';

const Feature = ({ title, text, icon: { publicURL: src } }) => (
	<div className={styles.wrapper}>
		<div className={styles.icon}>
			<SVG path={src} className={styles.svg} />
		</div>
		<div className={styles.content}>
			<h2 className={styles.title}>{title}</h2>
			<div className={styles.text}>
				<Markdown content={text} />
			</div>
		</div>
	</div>
);

Feature.defaultProps = {
	title: null,
	icon: null,
	text: null
};

Feature.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.shape({
		publicURL: PropTypes.string.isRequired
	}).isRequired,
	text: PropTypes.string
};

export default Feature;
