import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocaleContext from '../contexts/locale';

const all = [];
const uuidMap = {};
const localeMap = {};

export const useServiceByUuid = uuid => {
	invokeQueryAndMapData();

	if (uuid in uuidMap) {
		return uuidMap[uuid];
	}

	return null;
};

const invokeQueryAndMapData = () => {
	const {
		allMarkdownRemark: { edges: locations }
	} = useStaticQuery(query);

	const { currentLocale } = useContext(LocaleContext);

	const mapItem = ({ node: { frontmatter: data, fields } }) => ({
		...data,
		...fields
	});

	if (all.length === 0) {
		for (let location of locations) {
			const item = mapItem(location);
			const { uuid, locale } = item;

			localeMap[locale] = localeMap[locale] || [];
			localeMap[locale].push(item);
			uuidMap[uuid] = item;
			all.push(item);
		}
	}

	return localeMap[currentLocale] || [];
};

export default invokeQueryAndMapData;

const query = graphql`
	query useServices {
		allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "service" } } }
			sort: { fields: frontmatter___navigation___order }
		) {
			edges {
				node {
					fields {
						slug
						parent
					}
					frontmatter {
						locale
						uuid
						name
						navigation {
							icon {
								publicURL
							}
						}
						features {
							title
							text
							icon {
								publicURL
							}
						}
						videos
					}
				}
			}
		}
	}
`;
