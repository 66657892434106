import React from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import { useServiceByUuid } from '../hooks/use-services';

import Teaser from './service/teaser';
import Feature from './service/feature';
import Customers from './shared/customers';
import HighlightYouTube from './shared/highlight-youtube';

import styles from './service.module.scss';

const Service = ({
  data: {
    child: {
      frontmatter: { uuid },
      fields: { parent }
    },
    parent: { html }
  }
}) => {
  const { slug, features, videos } = useServiceByUuid(uuid) || {};

  return (
    <Page slug={slug} teaser={<Teaser content={html} />}>
      {Array.isArray(features) &&
        features.map(({ title, text, icon }, index) => (
          <div key={index} className={styles.feature}>
            <Feature text={text} title={title} icon={icon} />
          </div>
        ))}
        
      {Array.isArray(videos) &&
        videos.map((uuid, index) => (
          <div key={index} className={styles.video}>
            <HighlightYouTube uuid={uuid} />
          </div>
        ))}
      <Customers />
    </Page>
  );
};

export const serviceQuery = graphql`
  query serviceQuery($remarkId: String!, $locale: String!) {
    parent: markdownRemark(fields: { key: { eq: "services" }, locale: { eq: $locale } }) {
      html
    }

    child: markdownRemark(id: { eq: $remarkId }) {
      frontmatter {
        uuid
      }
      fields {
        parent
      }
    }
  }
`;

export default Service;
